import React, { useState } from 'react'
import { TopMenu } from './TopMenu'
import { Link } from 'gatsby'
import LogoText from '../images/logo/logotext.svg'

export const Header = ({ topMenu, activeDocMeta }) => {
  const [navOpen, toggleNav] = useState('false')

  const handleNav = () => {
    toggleNav(!navOpen)
  }

  return (
    <header className="header">
      <div className="header__content-width">
        <button
          className={navOpen ? 'hamburger' : 'hamburger hamburger--open'}
          onClick={handleNav}
          aria-label="toggle mobile navigation"
        >
          <span className="hamburger__line"></span>
          <span className="hamburger__line"></span>
          <span className="hamburger__line"></span>
        </button>
        <Link
          to={activeDocMeta.lang === 'de-ch' ? '/' : `/en-gb`}
          className="header__logo"
          aria-label="Back to Homepage"
        >
          <svg
            width="400"
            height="400"
            viewBox="0 0 400 400"
            fill="none"
            className="header__logo--icon"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="5"
              y="5"
              width="390"
              height="390"
              rx="20"
              stroke="black"
              strokeWidth="15"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M150 196L150 313C150 326.807 161.193 338 175 338H225C238.807 338 250 326.807 250 313L250 146H235L235 313C235 318.523 230.523 323 225 323H175C169.477 323 165 318.523 165 313L165 196H150Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M200 61L83 61C69.1929 61 58 72.1929 58 86L58 136C58 149.807 69.1929 161 83 161L250 161L250 146L83 146C77.4772 146 73 141.523 73 136L73 86C73 80.4772 77.4772 76 83 76L200 76L200 61Z"
              fill="black"
            />
            <path
              d="M200 61H292C319.614 61 342 83.3858 342 111V111C342 138.614 319.614 161 292 161H250C222.386 161 200 138.614 200 111V61Z"
              fill="#ED1F50"
            />
          </svg>
          <LogoText className="header__logo--text" />
        </Link>

        <TopMenu
          topMenu={topMenu}
          activeDocMeta={activeDocMeta}
          isActive={navOpen}
        />
      </div>
    </header>
  )
}

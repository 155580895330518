import React from 'react'
import { PrismicRichText } from '@prismicio/react'

export const FlowContr = ({ titles, changeSlide, isActive }) => {
  return (
    <div className="flow__contr">
      {titles.map((title, index) => {
        return (
          <span
            className={
              isActive === index
                ? 'flow__link flow__link--active'
                : 'flow__link'
            }
            key={index}
            id={index}
            onClick={(e) => changeSlide(e, index)}
          >
            <PrismicRichText field={title.slide_title.richText} />
          </span>
        )
      })}
    </div>
  )
}

import React from 'react'
import { PrismicLink, PrismicText } from '@prismicio/react'

export const FooterNavigation = ({ footerNavigation }) => {
  const renderFooterLinks = footerNavigation
    ? footerNavigation.footer_links.map((footerLink, index) => (
        <li key={`footer-nav-${index}`} className="footer__li">
          <PrismicLink href={footerLink.link.url}>
            <PrismicText field={footerLink.label.richText} />
          </PrismicLink>
        </li>
      ))
    : null

  return (
    <nav className="footer__navigation">
      <ul className="footer__ul">{renderFooterLinks}</ul>
    </nav>
  )
}

import React, { useState, useRef } from "react";
import axios from "axios";
import { graphql } from "gatsby";
import { PrismicRichText } from '@prismicio/react'
import { asText } from '@prismicio/helpers'
import Phone from '../images/contactphone.svg' 
import Whatsapp from '../images/whatsapp.svg'
import Linkedin from '../images/linkedin.svg'
import Mail from '../images/icons/mail-sent.svg'

export const ContactForm = ({ slice }) => {
    const form = useRef(null)

    const [isOpenModal, setOpenModal] = useState("false");
    const [isErr, setErr] = useState('false')

    const handleToggle = () => {
        setOpenModal(!isOpenModal)
    }

    const handleErr = () => {
        setErr(!isErr)
    }


    const [contact, setContact] = useState({
        name: '',
        email: '',
        tel: '',
        message: '',
    })

    const handleChange = (e) => {
        setContact({
            ...contact,
            [e.target.name]: e.target.value,
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        axios({
            method: "POST",
            url: "https://www.turnerweb.ch/cgi-bin/mail.php",
            data: contact
        }).then(result => {
            if(result.data.status === 'success') {
                handleToggle()
                form.current.reset()
            } else if (result.data.status === 'fail') {
                handleErr()
            }
        })
    }

    return (
        <section className="contact">
            <div className={isOpenModal ? 'contact__modal contact__modal--hide' : 'contact__modal'}>
                <div className="contact__modal-box">
                <Mail className="contact__sent" />
                <PrismicRichText field={slice.primary.success.richText} />
                <button className="contact__modal-close" onClick={handleToggle}>
                    {slice.primary.success_btn}
                </button>
                </div>
            </div>
            <div className="contact__content-width">
                <div className="contact__aside">
                <Phone className="contact__img phonesvg" />
                <div className="contact__social-container">
                    <a
                    href="https://wa.me/41788154165"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Whatsapp"
                    >
                    <Whatsapp className="contact__icon" />
                    </a>
                    <a
                    href="www.linkedin.com/in/stefan-turner"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Linkedin"
                    >
                    <Linkedin className="contact__icon" />
                    </a>
                    {/*             <a
                    href="http://www.google.ch"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Instagram"
                    >
                    <Instagram className="contact__icon" />
                    </a> */}
                    <div className="contact__email-container">
                    <a
                        href={`mailto: ${asText(slice.primary.email_address.richText)}`}
                        className="contact__email"
                    >
                        <PrismicRichText field={slice.primary.email_address.richText} />
                    </a>
                    </div>
                </div>
                </div>
                <form className="contact__form" onSubmit={handleSubmit} method="POST" ref={form}>
                <div className="contact__title-container">
                    <PrismicRichText field={slice.primary.title.richText} />
                    <span className="contact__req">
                    {asText(slice.primary.req.richText)}
                    </span>
                </div>
                <label className="contact__labels">
                    <span className="contact__label">
                    {asText(slice.primary.name_label.richText)} *
                    </span>
                    <input
                    className="contact__input"
                    type="text"
                    name="name"
                    onChange={handleChange}
                    required
                    />
                </label>
                <label className="contact__labels">
                    <span className="contact__label">
                    {asText(slice.primary.email_label.richText)} *
                    </span>
                    <input
                    className="contact__input"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    required
                    />
                </label>
                <label className="contact__labels contact__labels--subj">
                    <span className="contact__label">
                    {asText(slice.primary.subj_label.richText)}
                    </span>
                    <input
                    className="contact__input"
                    type="tel"
                    name="tel"
                    onChange={handleChange}
                    />
                </label>
                <label className="contact__labels">
                    <span className="contact__label">
                    {asText(slice.primary.msg_label.richText)} *
                    </span>
                    <textarea
                    className="contact__textarea"
                    name="message"
                    required
                    onChange={handleChange}
                    rows="10"
                    />
                </label>
                <button type="submit" className="contact__submit">
                    {asText(slice.primary.btn_text.richText)}
                </button>
                <div className={isErr ? 'contact__err contact__err--hide' : 'contact__err'}>
                    <PrismicRichText field={slice.primary.no_success.richText} />
                </div>
                </form>
            </div>
        </section>
    )
}

export const query = graphql`
  fragment PageDataBodyContactForm on PrismicPageDataBodyContactForm {
    primary {
      title {
        richText
      }
      btn_text {
        richText
      }
      email_label {
        richText
      }
      msg_label {
        richText
      }
      name_label {
        richText
      }
      subj_label {
        richText
      }
      tel_label {
        richText
      }
      req {
        richText
      }
      email_address {
        richText
      }
      no_success {
        richText
      }
      success {
        richText
      }
      success_btn
    }
  }
`
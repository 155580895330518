import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'

export const AboutMe = ({ slice }) => {
  return (
    <section className="about">
      <div className="about__content-width">
        <PrismicRichText field={slice.primary.sectiontitle.richText} />
        <div className="about__col">
          <GatsbyImage
            image={slice.primary.img.localFile.childImageSharp.gatsbyImageData}
            className="about__img"
            loading="lazy"
            alt={slice.primary.img.alt}
          />
          <div className="about__p-container">
            <PrismicRichText field={slice.primary.text.richText} />
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyAboutMe on PrismicHomepageDataBodyAboutMe {
    primary {
      img {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              formats: [AUTO, WEBP, AVIF]
              placeholder: BLURRED
              width: 675
            )
          }
        }
      }
      sectiontitle {
        richText
      }
      text {
        richText
      }
    }
  }
  fragment PageDataBodyAboutMe on PrismicPageDataBodyAboutMe {
    primary {
      img {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              formats: AUTO
              placeholder: BLURRED
              width: 675
            )
          }
        }
      }
      sectiontitle {
        richText
      }
      text {
        richText
      }
    }
  }
`

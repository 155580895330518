import React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const PageHero = ({ slice }) => {
  return (
    <section className="pagehero">
      <div className="pagehero__content-width">
        <PrismicRichText field={slice.primary.title.richText} />
        <PrismicRichText field={slice.primary.text.richText} />
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyPageHero on PrismicPageDataBodyPageHero {
    primary {
      text {
        richText
      }
      title {
        richText
      }
    }
  }
`

import React from 'react'
import Dots from '../images/dots.svg'
import Curve from '../images/bg/curved-bg.svg'
import { PrismicRichText } from '@prismicio/react'
import { Link } from 'gatsby'
import Arrows from '../images/icons/arrows.svg'

export const ScrollAni = ({ scrollText }) => {
  const rN = Math.floor(Math.random() * 5)

  const url = 'https://ik.imagekit.io/4gik3riqcq8/twd/'

  const imgs = [
    'car_ND9k9xPlV.png',
    'restaurant_25p-QDd5w.png',
    'brewery_rseep8FM9.png',
    'home_CisnGTQd4ek.png',
    'rest_1a4xPVUf4.png',
  ]

  return (
    <section className="scroll">
      <div className="scroll__bg">
        <Curve className="scroll__curve" />
        <div className="scroll__curve-body"></div>
      </div>
      <Dots className="scroll__dots" />
      <div className="scroll__container">
        <img
          src={`${url}tr:w-900/${imgs[rN]}`}
          className="scroll__img slide-up"
          width="1800"
          height="1053"
          sizes="(min-width: 900px) 900px, 100vw"
          loading="eager"
          srcSet={`
            ${url}tr:w-225/${imgs[rN]} 225w,
            ${url}tr:w-450/${imgs[rN]} 450w,
            ${url}tr:w-900/${imgs[rN]} 900w,
            ${url}tr:w-1800/${imgs[rN]} 1800w
          `}
          alt="screenshot"
        />
        <div className="scroll__text-container">
          <PrismicRichText field={scrollText.richText} />
          <Link to="#slicezone" aria-label="next section">
            <div className="hero__scroll">
              <Arrows className="hero__arrows" />
            </div>
          </Link>
        </div>
      </div>
    </section>
  )
}

import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'

export const MyServices = ({ slice }) => {
  return (
    <section className="services" id="services">
      <div className="services__content-width">
        <div className="services__main">
          <PrismicRichText field={slice.primary.main.richText} />
        </div>
      </div>

      <div className="services__card-container">
        {slice.items.map((item, i) => {
          return (
            <div className="services__card" key={i}>
              <GatsbyImage
                image={item.img.localFile.childImageSharp.gatsbyImageData}
                alt={item.img.alt ? item.img.alt : ''}
                loading="lazy"
                className="services__img"
              />
              <div className="services__card-text">
                <PrismicRichText field={item.subsection.richText} />
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyMyServices on PrismicHomepageDataBodyMyServices {
    items {
      img {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              layout: CONSTRAINED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      subsection {
        richText
      }
    }
    primary {
      main {
        richText
      }
    }
  }
`

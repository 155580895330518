import React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText, PrismicLink, PrismicText } from '@prismicio/react'

export const CallToAction = ({ slice }) => {
  return (
    <section className="cta">
      <div className="cta__content-width">
        <div className="cta__text-container">
          <PrismicRichText field={slice.primary.cta_title.richText} />
          <PrismicRichText field={slice.primary.cta_text.richText} />
        </div>
        <PrismicLink href={slice.primary.cta_button.url}>
          <PrismicText field={slice.primary.cta_button_label.richText} />
        </PrismicLink>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyCallToAction on PrismicHomepageDataBodyCallToAction {
    primary {
      cta_title {
        richText
      }
      cta_text {
        richText
      }
      cta_button_label {
        richText
      }
      cta_button {
        id
        url
      }
    }
  }
  fragment PageDataBodyCallToAction on PrismicPageDataBodyCallToAction {
    primary {
      cta_title {
        richText
      }
      cta_text {
        richText
      }
      cta_button_label {
        richText
      }
      cta_button {
        id
        url
      }
    }
  }
`

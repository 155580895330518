import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'

import { Layout } from '../components/Layout'
import { components } from '../slices'

const PageTemplate = ({ data }) => {
  if (!data) return null

  const pageContent = data.prismicPage
  const page = pageContent.data || {}

  const { lang, type, url } = pageContent
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }
  const topMenu = data.prismicTopMenu || {}
  const footerNavigation = data.prismicFooterNavigation
  const imprLinks = data.prismicFooterImpressumLinks

  return (
    <Layout
      topMenu={topMenu.data}
      activeDocMeta={activeDoc}
      footerNavigation={footerNavigation.data}
      imprLinks={imprLinks.data}
      title={page.title_tag}
      descr={page.descr_tag}
    >
      <SliceZone slices={page.body} components={components} />
    </Layout>
  )
}

export const query = graphql`
  query pageQuery($id: String, $lang: String) {
    prismicPage(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      uid
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        descr_tag
        title_tag
        body {
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ...PageDataBodyCallToAction
          ...PageDataBodyAboutMe
          ...PageDataBodyPageHero
          ...PageDataBodyWork
          ...PageDataBodyImageRow
          ...PageDataBodyContactForm
          ...PageDataBodyWriteContent
        }
      }
    }
    prismicTopMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
    }
    prismicFooterNavigation(lang: { eq: $lang }) {
      _previewable
      type
      lang
      data {
        footer_links {
          label {
            html
            richText
            text
          }
          link {
            id
            url
          }
        }
      }
    }
    prismicFooterImpressumLinks(lang: { eq: $lang }) {
      _previewable
      type
      lang
      data {
        footer_links {
          label {
            html
            richText
            text
          }
          link {
            id
            url
          }
        }
      }
    }
  }
`

export default withPrismicPreview(PageTemplate)

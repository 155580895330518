import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'

export const Seo = ({ inview, title, desc, meta }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            image
            siteUrl
          }
        }
      }
    `,
  )

  const url = site.siteMetadata.siteUrl
  const image = site.siteMetadata.image

  const { defaultLanguage } = require('../../prismic-configuration')

  return (
    <Helmet htmlAttributes={{ lang: meta.lang }}>
      <body className={inview ? '' : 'animate'}></body>
      <title>{title}</title>
      <meta name="robots" content="noindex" />
      <meta name="description" content={desc} />
      <link rel="canonical" href={url + meta.url} />

      <link rel="alternate" href={url + meta.url} hrefLang={meta.lang} />
      {meta.alternateLanguages.map((item, index) => {
        if (item.type === 'homepage') {
          return (
            <link
              key={index}
              rel="alternate"
              href={
                item.lang === defaultLanguage
                  ? url + '/'
                  : url + '/' + item.lang
              }
              hrefLang={item.lang}
            />
          )
        } else {
          return (
            <link
              key={index}
              rel="alternate"
              href={
                item.lang === defaultLanguage
                  ? url + '/' + item.uid
                  : url + '/' + item.lang + '/' + item.uid
              }
              hrefLang={item.lang}
            />
          )
        }
      })}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={desc} />
      <meta property="og:image" content={url + image} />
      <meta property="og:type" content="website" />
    </Helmet>
  )
}

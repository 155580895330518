import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'
import { asText } from '@prismicio/helpers'
import Arrow from '../images/icons/arrow-right.svg'

export const Work = ({ slice }) => {
  return (
    <section className="work">
      <div className="work__content-width">
        <GatsbyImage
          image={slice.primary.img.localFile.childImageSharp.gatsbyImageData}
          className="work__img"
          alt={slice.primary.img.alt}
          loading={slice.primary.first === true ? 'eager' : 'lazy'}
        />
        <div className="work__description">
          <PrismicRichText field={slice.primary.list.richText} />
          <PrismicRichText field={slice.primary.title.richText} />
          <PrismicRichText field={slice.primary.description.richText} />
          <a
            href={slice.primary.url.url}
            className={
              slice.primary.url.url === null
                ? 'work__link work__link--hide'
                : 'work__link'
            }
          >
            {asText(slice.primary.btn_text.richText)}
            <Arrow className="arrow" />
          </a>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyWork on PrismicPageDataBodyWork {
    primary {
      first
      btn_text {
        richText
      }
      description {
        richText
      }
      img {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 800
            )
          }
        }
      }
      list {
        richText
      }
      name {
        richText
      }
      role {
        richText
      }
      subtitle {
        richText
      }
      tm_title {
        richText
      }
      testimonial {
        richText
      }
      title {
        richText
      }
      url {
        url
      }
      tm_avail
    }
  }
`

import * as React from 'react'
import { useInView } from 'react-intersection-observer'
import { Header } from './Header'
import { Footer } from './Footer'
import { Seo } from './Seo'

export const Layout = ({
  children,
  topMenu,
  activeDocMeta,
  footerNavigation,
  imprLinks,
  position,
  title,
  descr,
}) => {
  const [ref, inView] = useInView({ initialInView: true })

  return (
    <>
      <Seo inview={inView} title={title} desc={descr} meta={activeDocMeta} />

      <div ref={ref}></div>
      <Header
        topMenu={topMenu}
        activeDocMeta={activeDocMeta}
        position={position}
      />
      <main id="scroll">{children}</main>
      <Footer footerNavigation={footerNavigation} imprLinks={imprLinks} />
    </>
  )
}

import React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const WriteContent = ({ slice }) => {
  return (
    <section className="content">
      <div className="content__content-width">
        <PrismicRichText field={slice.primary.content.richText} />
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyWriteContent on PrismicPageDataBodyWriteContent {
    primary {
      content {
        richText
      }
    }
  }
`

import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'

export const ImageRow = ({ slice }) => {
  return (
    <section className="imgrow">
      {slice.items.map((item, index) => {
        return (
          <div className="imgrow__container" key={index}>
            <PrismicRichText field={item.title.richText} />
            <GatsbyImage
              image={item.img.localFile.childImageSharp.gatsbyImageData}
              className="imgrow__img"
              loading="lazy"
              alt={item.img.alt || ''}
            />
            <a
              href={item.link.url}
              className={
                item.link.url === null ? 'imgrow__link--hide' : 'imgrow__link'
              }
            >
              <PrismicRichText field={item.text.richText} />
            </a>
          </div>
        )
      })}
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyImageRow on PrismicPageDataBodyImageRow {
    items {
      img {
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 750
            )
          }
        }
      }
      link {
        url
      }
      text {
        richText
      }
      title {
        richText
      }
    }
  }
`

import * as React from 'react'
import Logo from '../images/logo/logo-footer.svg'
import Whatsapp from '../images/whatsapp.svg'
import Linkedin from '../images/linkedin.svg'
import Insta from '../images/instagram.svg'
import { FooterNavigation } from './FooterNavigation'
import { ImpressumLinks } from './ImpressumLinks'

export const Footer = ({ footerNavigation, imprLinks }) => (
  <footer className="footer">
    <div className="footer__content-width">
      <Logo className="footer__logo" />
      <FooterNavigation footerNavigation={footerNavigation} />
      <span className="footer__seperator"></span>
      <div className="footer__icons-container">
        <a
          href="https://wa.me/41788154165"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Whatsapp"
        >
          <Whatsapp />
        </a>
        <a
          href="www.linkedin.com/in/stefan-turner"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Linkedin"
        >
          <Linkedin />
        </a>
        {/*         <a
          href="http://www.google.ch"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Instagram"
        >
          <Insta />
        </a> */}
      </div>
      <p className="footer__copyright">
        {new Date().getFullYear()} www.turnerweb.ch
      </p>
      <ImpressumLinks imprLinks={imprLinks} />
    </div>
  </footer>
)

module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"turnerweb","accessToken":"MC5ZaDVGTEJJQUFDMEE5Q2Fj.77-977-9QO-_vT7vv73vv73vv70Y77-9bu-_vQzvv73vv73vv73vv73vv73vv73vv73vv71oKu-_ve-_vXrvv73vv73vv73vv73vv73vv70","promptForAccessToken":true,"apiEndpoint":"https://turnerweb.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"TurnerWeb","short_name":"TW","start_url":"/","background_color":"#2c2543","theme_color":"#fff","display":"standalone","icon":"src/images/logo/logo.svg","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"1780501b6c6a73bcacaf43a4f3f5b07f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-0HJ1YLFM49"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"respectDNT":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

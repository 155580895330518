import { AboutMe } from './AboutMe'
import { CallToAction } from './CallToAction'
import { ProjectFlow } from './ProjectFlow'
import { PageHero } from './PageHero'
import { ContactForm } from './ContactForm'
import { Work } from './Work'
import { ImageRow } from './ImageRow'
import { MyServices } from './MyServices'
import { WriteContent } from './WriteContent'

export const components = {
  about_me: AboutMe,
  project_flow: ProjectFlow,
  call_to_action: CallToAction,
  page_hero: PageHero,
  contact_form: ContactForm,
  work: Work,
  image_row: ImageRow,
  my_services: MyServices,
  write_content: WriteContent,
}

import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import Develop from './flow-img/develop.svg'
import Plan from './flow-img/planing.svg'
import Test from './flow-img/test.svg'
import Live from './flow-img/golive.svg'
import { FlowContr } from '../components/FlowContr'

export const ProjectFlow = ({ slice }) => {
  const [position, setPosition] = useState(0)
  const [isActive, setActive] = useState(0)
  let mq

  if (typeof window !== `undefined`) {
    mq = window.matchMedia('(max-width: 768px)')
  }

  useEffect(() => {
    toggle()

    mq.addListener(toggle)

    return () => mq.removeListener(toggle)
  }, [])

  const toggle = () => {
    if (mq.matches) {
      setPosition(0)
      setActive(0)
    } else {
      setPosition(0)
    }
  }

  const changeSlide = (e, pos) => {
    setActive(parseInt(e.target.id))

    switch (pos) {
      case 0:
        return setPosition(0)
      case 1:
        return setPosition(25)
      case 2:
        return setPosition(50)
      case 3:
        return setPosition(75)
    }
  }

  return (
    <section className="flow">
      <div className="flow__content-width">
        <PrismicRichText field={slice.primary.title.richText} />
        <FlowContr
          titles={slice.items}
          changeSlide={changeSlide}
          isActive={isActive}
        />
        <div
          className="flow__slider"
          style={{ transform: `translateX(-${position}%)` }}
        >
          <div className="flow__slide">
            <div className="flow__slide-text">
              <PrismicRichText field={slice.items[0].slide_title.richText} />
              <PrismicRichText field={slice.items[0].slide_text.richText} />
            </div>
            <img
              src={Plan}
              className="flow__slide-img"
              loading="lazy"
              width="202"
              height="188"
              alt="illustration"
            />
          </div>
          <div className="flow__slide">
            <div className="flow__slide-text">
              <PrismicRichText field={slice.items[1].slide_title.richText} />
              <PrismicRichText field={slice.items[1].slide_text.richText} />
            </div>
            <img
              src={Develop}
              className="flow__slide-img"
              loading="lazy"
              width="237"
              height="149"
              alt="illustration"
            />
          </div>
          <div className="flow__slide">
            <div className="flow__slide-text">
              <PrismicRichText field={slice.items[2].slide_title.richText} />
              <PrismicRichText field={slice.items[2].slide_text.richText} />
            </div>
            <img
              src={Test}
              className="flow__slide-img"
              loading="lazy"
              width="192"
              height="177"
              alt="illustration"
            />
          </div>
          <div className="flow__slide">
            <div className="flow__slide-text">
              <PrismicRichText field={slice.items[3].slide_title.richText} />
              <PrismicRichText field={slice.items[3].slide_text.richText} />
            </div>
            <img
              src={Live}
              className="flow__slide-img"
              loading="lazy"
              width="301"
              height="171"
              alt="illustration"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyProjectFlow on PrismicHomepageDataBodyProjectFlow {
    primary {
      title {
        richText
      }
    }
    items {
      slide_text {
        richText
      }
      slide_title {
        richText
      }
    }
  }
`

import * as React from 'react'
import { navigate } from 'gatsby'

import { linkResolver } from '../utils/linkResolver'

export const LanguageSwitcher = ({ activeDocMeta }) => {
  const handleLangChange = () => {
    navigate(linkResolver(activeDocMeta.alternateLanguages[0]))
  }

  return (
    <li className="language-switcher" onClick={handleLangChange}>
      {activeDocMeta.lang === 'de-ch' ? 'English' : 'Deutsch'}
    </li>
  )
}

import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicLink, PrismicText } from '@prismicio/react'

import { LanguageSwitcher } from './LanguageSwitcher'

export const TopMenu = ({ topMenu, activeDocMeta, isActive }) => {
  const renderedMenuLinks = topMenu
    ? topMenu.menu_links.map((menuLink, index) => (
        <li key={`top-nav-${index}`}>
          <PrismicLink href={menuLink.link.url}>
            <PrismicText field={menuLink.label.richText} />
          </PrismicLink>
        </li>
      ))
    : null

  return (
    <nav className={isActive ? 'navigation' : 'navigation navigation--open'}>
      <ul className="navigation__ul">
        {renderedMenuLinks}
        <LanguageSwitcher activeDocMeta={activeDocMeta} />
      </ul>
    </nav>
  )
}

export const query = graphql`
  fragment TopMenuFragment on PrismicTopMenu {
    _previewable
    type
    lang
    data {
      menu_links {
        label {
          richText
          html
          text
        }
        link {
          id
          url
        }
      }
    }
  }
`

import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import Arrows from '../images/icons/arrows.svg'
import { Link } from 'gatsby'
import Drawn from '../images/icons/drawn-line.svg'

export const Hero = ({ hero }) => {
  return (
    <section className="hero">
      <div className="hero__content-width">
        <PrismicRichText field={hero.title.richText} />
        <Drawn className="hero__drawn" />
        <p className="hero__text">{hero.paragraph}</p>
        <Link to="#scroll" aria-label="next section">
          <div className="hero__scroll">
            <Arrows className="hero__arrows" />
          </div>
        </Link>
      </div>
    </section>
  )
}

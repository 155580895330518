import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'

import { Layout } from '../components/Layout'
import { Hero } from '../components/Hero'
import { ScrollAni } from '../components/ScrollAni'
import { components } from '../slices'

const HomepageTemplate = ({ data }) => {
  if (!data) return null

  const homepage = data.prismicHomepage || {}
  const topMenu = data.prismicTopMenu || {}
  const footerNavigation = data.prismicFooterNavigation
  const imprLinks = data.prismicFooterImpressumLinks
  const hero = data.prismicHero
  const scrollText = data.prismicHomepage.data.scroll_text

  const { lang, type, url } = homepage || {}
  const alternateLanguages = homepage.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  return (
    <Layout
      topMenu={topMenu.data}
      activeDocMeta={activeDoc}
      footerNavigation={footerNavigation.data}
      imprLinks={imprLinks.data}
      title={homepage.data.title_tag}
      descr={homepage.data.desc_tag}
    >
      <Hero hero={hero.data} />
      <ScrollAni scrollText={scrollText} />
      <div className="slicezone" id="slicezone">
        <SliceZone slices={homepage.data.body} components={components} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query homepageQuery($lang: String) {
    prismicHomepage(lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      data {
        scroll_text {
          richText
        }
        desc_tag
        title_tag
        body {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
          ...HomepageDataBodyCallToAction
          ...HomepageDataBodyAboutMe
          ...HomepageDataBodyProjectFlow
          ...HomepageDataBodyMyServices
        }
      }
    }
    prismicTopMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
    }
    prismicFooterNavigation(lang: { eq: $lang }) {
      _previewable
      type
      lang
      data {
        footer_links {
          label {
            html
            text
            richText
          }
          link {
            id
            url
          }
        }
      }
    }
    prismicFooterImpressumLinks(lang: { eq: $lang }) {
      _previewable
      type
      lang
      data {
        footer_links {
          label {
            html
            richText
            text
          }
          link {
            id
            url
          }
        }
      }
    }
    prismicHero(lang: { eq: $lang }) {
      _previewable
      data {
        paragraph
        title {
          html
          richText
          text
        }
      }
    }
  }
`

export default withPrismicPreview(HomepageTemplate)

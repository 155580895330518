import React from 'react'
import { PrismicLink, PrismicText } from '@prismicio/react'

export const ImpressumLinks = ({ imprLinks }) => {
  const renderFooterLinks = imprLinks
    ? imprLinks.footer_links.map((footerLink, index) => (
        <PrismicLink href={footerLink.link.url} key={index}>
          <PrismicText field={footerLink.label.richText} />
        </PrismicLink>
      ))
    : null

  return <div className="footer__impressum-container">{renderFooterLinks}</div>
}
